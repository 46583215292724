import {
  Button,
  Constraint,
  Container,
  FlexGrid,
  Header,
  Text,
} from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useCallback, useMemo } from 'react';
import useAnalytics from '../../../lib/hooks/useAnalytics';
import { ANALYTICS_EVENTS } from '../../constants';
import styles from './SavingsTools.module.css';

const cx = classnames.bind(styles);

const TOOLS = [
  {
    name: 'Trip Cost Calculator',
    key: 'Trip_Cost_Calculator',
    description: 'Plan your next road trip with our handy tool that can help you save.',
    href: '//www.gasbuddy.com/tripcostcalculator',
  },
  {
    name: 'Outage Tracker',
    key: 'Outage_Tracker',
    description: 'Find out which stations have power and fuel during natural disasters.',
    href: '//tracker.gasbuddy.com',
  },
  {
    name: 'Gas Price Map',
    key: 'Gas_Price_Map',
    description: 'Explore real-time gas prices in specific areas or wide-angle trends.',
    href: '//www.gasbuddy.com/gaspricemap',
  },
  {
    name: 'Fuel Insights',
    key: 'Fuel_Insights',
    description: 'Explore price trends by various regions and time periods.',
    href: '//fuelinsights.gasbuddy.com',
  },
  {
    name: 'Fuel Logbook',
    key: 'Fuel_Logbook',
    description: 'Log your fuel purchases and track your fuel economy',
    href: '/account/fuel-log-book',
  },
  {
    name: 'Vehicle Recalls',
    key: 'Vehicle_Recalls',
    description: 'Find out if your vehicle has any recalls, and what to do about it!',
    href: '/vehicles/recalls/search',
  },
];

export default function SavingsTools({
  className,
  tools,
  ...rest
}) {
  const analytics = useAnalytics();

  const linkClickHandler = useCallback(pageName => () => {
    if (pageName === 'Vehicle_Recalls') {
      analytics.tagEvent({
        name: ANALYTICS_EVENTS.OPEN_RECALL_SEARCH_PAGE,
        attributes: {
          Page: pageName,
          source: 'saving_tools_link',
        },
      });
    } else {
      analytics.tagEvent({
        name: ANALYTICS_EVENTS.SAVINGS_LINK_CLICK,
        attributes: { Page: pageName },
      });
    }
  }, [analytics]);

  const toolClickHandlers = useMemo(() => tools.map(({ key }) => linkClickHandler(key)), [linkClickHandler, tools]);

  return (
    <div className={cx('container', className)} {...rest}>
      <Container>
        <Header as="h2" className={cx('header')}>Savings tools</Header>
        <Text as="p" centered className={cx('text')}>
          Our gas tools can help you find a wide range of information on gas prices and how to save every time you drive.
        </Text>
        <br />
        <br />
        <Constraint centered tablet={10} desktop={8}>
          <FlexGrid className={cx('tools')} container>
            {tools.map(({ description, href, name }, index) => (
              <FlexGrid.Column key={href} mobile={12} tablet={6}>
                <Button
                  as="a"
                  className={cx('tool')}
                  size="lg"
                  fluid
                  href={href}
                  onClick={toolClickHandlers[index]}
                  secondary
                >
                  <span className={cx('toolLinkLabel')}>{name}</span>
                  <p className={cx('toolLinkDescription')}>{description}</p>
                </Button>
              </FlexGrid.Column>
            ))}
          </FlexGrid>
        </Constraint>
      </Container>
    </div>
  );
}

SavingsTools.propTypes = {
  className: PropTypes.string,
  tools: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
  })),
};

SavingsTools.defaultProps = {
  className: undefined,
  tools: TOOLS,
};
