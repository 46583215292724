export const SEARCHES = {
  key: 'searches',
  header: '900M',
  text: 'Searches for\n cheap gas',
  iconUrl: 'https://static.gasbuddy.com/web/consumer/metric-location-pin.svg',
};

export const CARDHOLDERS = {
  key: 'cardholders',
  header: '700K',
  text: 'GasBuddy\n cardholders',
  iconUrl: 'https://static.gasbuddy.com/web/consumer/metric-avatar.svg',
};

export const GASBACKEARNERS = {
  key: 'gasbackearners',
  header: CARDHOLDERS.header,
  text: 'GasBack\n earners',
  iconUrl: 'https://static.gasbuddy.com/web/consumer/metric-avatar.svg',
};

export const CARDTRANSACTIONS = {
  key: 'cardtransactions',
  header: '15M',
  text: 'Total GasBuddy\n card transactions',
  iconUrl: 'https://static.gasbuddy.com/web/consumer/metric-pay-at-pump.svg',
};

export const GASBACKTRANSACTIONS = {
  key: 'gasbacktransactions',
  header: CARDTRANSACTIONS.header,
  text: 'Total GasBuddy\n card transactions',
  iconUrl: 'https://static.gasbuddy.com/web/consumer/metric-pay-at-pump.svg',
};

export const CARDSAVINGS = {
  key: 'cardsavings',
  header: '$12M',
  text: 'Total GasBuddy\n card savings',
  iconUrl: 'https://static.gasbuddy.com/web/consumer/metric-cash.svg',
};

export const SAVINGSUSD = {
  key: 'savingsusd',
  header: '$705M',
  text: 'Total savings\n per year',
  iconUrl: 'https://static.gasbuddy.com/web/consumer/metric-cash.svg',
};

export const SAVINGSCAD = {
  key: 'savingscad',
  header: '$990M',
  text: 'Total savings\n per year',
  iconUrl: 'https://static.gasbuddy.com/web/consumer/metric-cash.svg',
};

export const CARDGALLONS = {
  key: 'cardgallons',
  header: '63M',
  text: 'Gallons of gas\n pumped',
  iconUrl: 'https://static.gasbuddy.com/web/consumer/metric-pump.svg',
};

export const CARDLITERS = {
  key: 'cardliters',
  header: '240M',
  text: 'Liters of gas\n pumped',
  iconUrl: 'https://static.gasbuddy.com/web/consumer/metric-pump.svg',
};

export const PAY = [
  SEARCHES,
  CARDHOLDERS,
  CARDTRANSACTIONS,
  CARDSAVINGS,
  CARDGALLONS,
];

export const NONPAY = [
  SEARCHES,
  SAVINGSCAD,
  CARDLITERS,
];

export const GASBACK = [
  GASBACKEARNERS,
  GASBACKTRANSACTIONS,
  CARDSAVINGS,
];
