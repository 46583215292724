export default function formatPrice(price, requiredDecimals = 0) {
  const num = parseFloat(price);
  if (requiredDecimals > 0) {
    return `$${num.toFixed(requiredDecimals)}`;
  }

  if (num > 0 && num < 1) {
    return `${num * 100}¢`;
  }

  return `$${num === parseInt(num, 10) ? num : num.toFixed(2)}`;
}
