import { Container, FlexGrid, Header, Image, Picture, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { COUNTRY_CODES } from '../../constants';
import CountryPropTypes from '../../prop-types/country';
import GetTheAppButton from '../GetTheAppButton';
import styles from './AppPromo.module.css';

const cx = classnames.bind(styles);

export default function AppPromo({ className, country, screenName }) {
  const isUs = country === COUNTRY_CODES.USA;
  const imageCountryCode = (isUs ? COUNTRY_CODES.USA : COUNTRY_CODES.CANADA).toLowerCase();
  const statement = isUs
    ? 'Get our top-rated app to find the best prices quickly while you\'re on the go and to access all GasBack offers.'
    : 'Get our top-rated app to quickly find the best prices and keep track of your vehicle while you\'re on the road.';
  const imageAlt = isUs
    ? 'iPhones with GasBuddy Map and GasBack Deals'
    : 'iPhones with GasBuddy Map and Vehicle Profile';

  return (
    <div className={cx('gradient', className)}>
      <Container>
        <FlexGrid>
          <FlexGrid.Column className={cx('statementContainer')} desktop={5}>
            <Image
              lazy
              alt="GasBuddy App"
              className={cx('icon')}
              src="https://static.gasbuddy.com/web/gb-app-2.svg"
            />
            <Header as="h2" className={cx('header')} color="grey">Savings on the go</Header>
            <Text>{statement}</Text>
            <br />
            <br />
            <GetTheAppButton cta={false} screen={screenName} section="AppPromo" />
          </FlexGrid.Column>
          <FlexGrid.Column className={cx('phonesContainer')} desktop={7}>
            <Picture
              lazy
              alt={imageAlt}
              className={cx('phones')}
              src={`https://static.gasbuddy.com/web/consumer/phones/home-phones-${imageCountryCode}.png`}
              srcSet={`
                https://static.gasbuddy.com/web/consumer/phones/home-phones-${imageCountryCode}@2x.png 2x,
                https://static.gasbuddy.com/web/consumer/phones/home-phones-${imageCountryCode}@3x.png 3x
              `}
            >
              <Picture.Source
                srcSet={`
                  https://static.gasbuddy.com/web/consumer/phones/home-phones-${imageCountryCode}.webp 1x,
                  https://static.gasbuddy.com/web/consumer/phones/home-phones-${imageCountryCode}@2x.webp 2x,
                  https://static.gasbuddy.com/web/consumer/phones/home-phones-${imageCountryCode}@3x.webp 3x
                `}
                type="image/webp"
              />
            </Picture>
          </FlexGrid.Column>
        </FlexGrid>
      </Container>
    </div>
  );
}

AppPromo.propTypes = {
  className: PropTypes.string,
  country: CountryPropTypes,
  /** The screenName the promo is appearing on. Used for the localytics event of the download app button */
  screenName: PropTypes.string,
};

AppPromo.defaultProps = {
  className: undefined,
  country: COUNTRY_CODES.USA,
  screenName: undefined,
};
