import { Header, Image, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './MetricsBox.module.css';

const cx = classnames.bind(styles);

export default function Metric({ className, header, iconUrl, text, ...rest }) {
  return (
    <div className={cx('metric', className)} {...rest}>
      <Image
        lazy
        alt={`${text} Icon`}
        className={cx('metricImage')}
        src={iconUrl}
      />
      <Header as="h3" className={cx('metricHeader')} color="red">{header}</Header>
      <Text className={cx('metricText')}>
        {text.split('\n').map(i => <span key={i}>{i}</span>)}
      </Text>
    </div>
  );
}

Metric.propTypes = {
  className: PropTypes.string,
  header: PropTypes.string,
  iconUrl: PropTypes.string,
  text: PropTypes.string,
};

Metric.defaultProps = {
  className: undefined,
  header: undefined,
  iconUrl: undefined,
  text: undefined,
};
