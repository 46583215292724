import { Header, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Helmet } from 'react-helmet-async';
import useImageSrcBuilder from '../../../lib/hooks/useImageSrcBuilder';
import { interpolate } from '../../../lib/utils';
import formatPrice from '../../../lib/utils/formatPrice';
import Markup from '../Markup';
import DefaultCallToAction from './DefaultCallToAction';
import styles from './LandingPageTemplate1.module.css';

const cx = classnames.bind(styles);

export default function Hero({ className, cta: CallToAction, buttonHref, heroData, isLoggedIn, offerPrice, onButtonClick }) {
  const imageToSrc = useImageSrcBuilder();
  return (
    <Fragment>
      {!!heroData && (
        <Helmet>
          <style>
            {`
              .${cx('bannerImage')} {
                background-image: url(${imageToSrc(heroData.mobileImage || heroData.image)});
              }
              @media (min-width: 992px) {
                .${cx('bannerImage')} {
                  background-image: url(${imageToSrc(heroData.image)});
                }
              }
            `}
          </style>
        </Helmet>
      )}
      <section className={cx('banner', className)}>
        <div className={cx('bannerText')}>
          <Header>
            <Markup source={interpolate(heroData?.title)} />
          </Header>
          <Markup source={interpolate(heroData?.description)} />
          <div className={cx('cta', 'hero-cta-wrapper')}>
            {!!offerPrice && (
              <Text bold centered className={cx('price')} fluid size="lg">Only {formatPrice(offerPrice)}/month*</Text>
            )}
            <CallToAction
              fluid
              href={buttonHref}
              id="heroCTA"
              isLoggedIn={isLoggedIn}
              onClick={onButtonClick}
            >
              {heroData?.ctaLabel}
            </CallToAction>
            {heroData?.ctaDisclaimer && (
              <Text className={cx('ctaDisclaimer')} size="sm">{heroData?.ctaDisclaimer}</Text>
            )}
          </div>
          <Text as="p">
            <Markup source={interpolate(heroData?.footer)} />
          </Text>
        </div>
        <div className={cx('bannerImage')} />
      </section>
    </Fragment>
  );
}

Hero.propTypes = {
  buttonHref: PropTypes.string.isRequired,
  className: PropTypes.string,
  cta: PropTypes.elementType,
  heroData: PropTypes.shape({
    ctaDisclaimer: PropTypes.string,
    ctaLabel: PropTypes.string.isRequired,
    description: PropTypes.string,
    footer: PropTypes.string,
    title: PropTypes.string.isRequired,
    mobileImage: PropTypes.shape({
      height: PropTypes.number,
      url: PropTypes.string.isRequired,
      width: PropTypes.number,
    }),
    image: PropTypes.shape({
      height: PropTypes.number,
      url: PropTypes.string.isRequired,
      width: PropTypes.number,
    }),
  }),
  isLoggedIn: PropTypes.bool,
  offerPrice: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onButtonClick: PropTypes.func.isRequired,
};

Hero.defaultProps = {
  className: undefined,
  cta: DefaultCallToAction,
  heroData: {},
  isLoggedIn: undefined,
  offerPrice: undefined,
};
