import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Fragment, useCallback, useState } from 'react';
import store from 'store';
import expirePlugin from 'store/plugins/expire';
import useAnalytics from '../../../lib/hooks/useAnalytics';
import { ANALYTICS_EVENTS } from '../../constants';
import AppBadges from '../AppBadges';
import styles from './MobileDownloadAd.module.css';

const cx = classnames.bind(styles);
const THREE_MONTHS_IN_MS = 1000 * 60 * 60 * 24 * 90;

export default function MobileDownloadAd({ analyticsScreenName, clearable }) {
  const storeLookupKey = `Clear_MobileDownloadAd_${analyticsScreenName}`;
  store.addPlugin(expirePlugin);
  store.removeExpiredKeys();
  const [clearAd, setClearAd] = useState(store.get(storeLookupKey));
  const analytics = useAnalytics();

  const closeAdHandler = useCallback((e) => {
    e.preventDefault();
    analytics.tagEvent({
      name: ANALYTICS_EVENTS.CLOSE_MOBILE_DOWNLOAD_AD,
      attributes: { 'Screen Name': analyticsScreenName },
    });
    store.set(storeLookupKey, true, new Date().getTime() + THREE_MONTHS_IN_MS);
    setClearAd(true);
  }, [analytics, analyticsScreenName, storeLookupKey]);

  if (clearable && clearAd) {
    return null;
  }

  return (
    <div className={cx('adContainer')}>
      {clearable && (
        <Fragment>
          <br />
          <button
            aria-label="Close Ad"
            className={cx('closeIcon')}
            onClick={closeAdHandler}
            type="button"
          >
            <FontAwesomeIcon icon={faTimes} size="2x" />
          </button>
        </Fragment>
      )}
      <Text
        as="p"
        bold
        className={cx('mobileDownloadAppText')}
      >
        Check prices faster and save more on gas. <br />
      </Text>
      <Text>
        Combine discounts, deals and the best price for instant savings of $2-$10 on every tank.
      </Text>
      <br />
      <br />
      <AppBadges analyticsScreenName={analyticsScreenName} fluid showReviewSections />
    </div>
  );
}

MobileDownloadAd.propTypes = {
  analyticsScreenName: PropTypes.string.isRequired,
  clearable: PropTypes.bool,
};

MobileDownloadAd.defaultProps = {
  clearable: false,
};
