import PropTypes from 'prop-types';
import { COUNTRY_CODES } from '../../constants';
import { PAY, NONPAY } from '../../constants/metrics';
import MetricsBox from '../MetricsBox';

export default function ReachMetrics({ country, platformTotals }) {
  const stats = country === COUNTRY_CODES.USA ? PAY : NONPAY;

  let mergedStats = stats;
  if (platformTotals) {
    mergedStats = stats.map((stat) => {
      const replacement = platformTotals.find(total => total.key === stat.key);
      if (replacement) {
        return Object.assign({}, stat, {
          header: replacement.value,
        });
      }

      return stat;
    });
  }

  return (
    <MetricsBox header="Helping people save on gas, every day." metrics={mergedStats} />
  );
}

ReachMetrics.propTypes = {
  country: PropTypes.oneOf(Object.values(COUNTRY_CODES)),
  platformTotals: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]).isRequired,
  })),
};

ReachMetrics.defaultProps = {
  country: COUNTRY_CODES.USA,
  platformTotals: [],
};
