import { faStar } from '@fortawesome/free-solid-svg-icons/faStar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import styles from './Testimonials.module.css';

const cx = classnames.bind(styles);

export default function TestimonialCard({ text, from, saved, stars, ...rest }) {
  return (
    <div className={cx('card')} {...rest}>
      <span className={cx('body')}>{text}</span>
      <br />
      <br />
      <div className={cx('divider')} />
      <div className={cx('from')}>
        {from}
        <br />
        {!!saved && <Text color="red">{`Saved $${saved}`}</Text>}
      </div>
      {!!stars && (
        <div className={cx('stars')}>
          {Array(Math.ceil(stars)).fill('').map((v, k) => (
            // eslint-disable-next-line react/no-array-index-key
            <FontAwesomeIcon key={`${from}-${k}`} icon={faStar} className={cx('star')} />
          ))}
        </div>
      )}
    </div>
  );
}

TestimonialCard.propTypes = {
  text: PropTypes.string,
  from: PropTypes.string,
  saved: PropTypes.number,
  stars: PropTypes.number,
};

TestimonialCard.defaultProps = {
  text: undefined,
  from: undefined,
  saved: undefined,
  stars: undefined,
};
