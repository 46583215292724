import { Carousel, FlexGrid, Panel } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import uniqid from 'uniqid';
import styles from './Testimonials.module.css';

const cx = classnames.bind(styles);

export default function Testimonials({ className, children, ...rest }) {
  // groups of 3
  const childGroups = Math.ceil(children.length / 3);
  // build components array. Children testimonials are grouped in
  // flexgrid container so we can 1) slide per group of 3 or less
  // and leverage flex's props from our components library
  const componentsArray = Array.from(Array(childGroups).keys()).reduce((acc, val) => {
    const index = val * 3;

    return acc.concat(
      (
        children.slice(index, index + 3).map(comp => (
          <FlexGrid.Column
            key={uniqid()}
            className={cx('gridContainer')}
          >
            {comp}
          </FlexGrid.Column>
        ))
      ),
    );
  }, []);

  return (
    <Panel className={cx('container', className)} color="white" {...rest}>
      <Carousel indicator className={cx('carousel')}>
        {componentsArray}
      </Carousel>
    </Panel>
  );
}

Testimonials.propTypes = {
  className: PropTypes.string,
  children: PropTypes.arrayOf(PropTypes.element),
};

Testimonials.defaultProps = {
  className: undefined,
  children: [],
};
