import { AppBadges } from '@gasbuddy/react-consumer-components';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import useAnalytics from '../../../lib/hooks/useAnalytics';
import { ANALYTICS_EVENTS } from '../../constants';

export default function AppBadgesWithAnalytics({ analyticsScreenName, ...rest }) {
  const analytics = useAnalytics();

  const handleAppStoreClick = useCallback(() => {
    analytics.tagEvent({
      name: ANALYTICS_EVENTS.APP_STORE_LINK_CLICK,
      attributes: { Store: 'iTunes', 'Screen Name': analyticsScreenName },
    });
  }, [analytics, analyticsScreenName]);

  const handlePlayStoreClick = useCallback(() => {
    analytics.tagEvent({
      name: ANALYTICS_EVENTS.APP_STORE_LINK_CLICK,
      attributes: { Store: 'PlayStore', 'Screen Name': analyticsScreenName },
    });
  }, [analytics, analyticsScreenName]);

  return (
    <AppBadges
      appStoreData={{
        name: 'iOSAppRating',
        rating: 4.5,
        reviewCount: '380,000+',
      }}
      onAppStoreClick={handleAppStoreClick}
      onPlayStoreClick={handlePlayStoreClick}
      playStoreData={{
        name: 'androidAppRating',
        rating: 4.5,
        reviewCount: '770,000+',
      }}
      {...rest}
    />
  );
}

AppBadgesWithAnalytics.propTypes = {
  analyticsScreenName: PropTypes.string.isRequired,
};
