import { Image } from '@gasbuddy/react-components';
import PropTypes from 'prop-types';
import classnames from 'classnames/bind';
import styles from './AnimatedPhone.module.css';
import { COUNTRY_CODES } from '../../constants';

const cx = classnames.bind(styles);

export default function AnimatedPhone({ country }) {
  const isUS = country === COUNTRY_CODES.USA;

  if (isUS) {
    return (
      <div className={cx('phoneUSGif')}>
        <Image
          lazy
          alt="GasBuddy App Notifications"
          src="https://static.gasbuddy.com/web/consumer/phones/app_push_content.gif"
        />
      </div>
    );
  }

  return (
    <div className={cx('phoneGifComp')}>
      <Image
        lazy
        alt="GasBuddy App"
        className={cx('gif')}
        src="https://static.gasbuddy.com/web/consumer/phones/iphone_X_content_nonpay.gif"
      />
      <Image
        lazy
        alt="GasBuddy App"
        src="https://static.gasbuddy.com/web/consumer/phones/iphone_X_frame.png"
        webpSrcSet="https://static.gasbuddy.com/web/consumer/phones/iphone_X_frame.webp"
      />
    </div>
  );
}

AnimatedPhone.propTypes = {
  country: PropTypes.oneOf(Object.values(COUNTRY_CODES)),
};

AnimatedPhone.defaultProps = {
  country: COUNTRY_CODES.USA,
};
