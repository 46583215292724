import parse from 'html-react-parser';
import PropTypes from 'prop-types';

export default function Markup({ source }) {
  return source ? parse(source) : null;
}

Markup.propTypes = {
  source: PropTypes.string,
};

Markup.defaultProps = {
  source: undefined,
};
