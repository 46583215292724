import { Button } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import styles from './LandingPageTemplate1.module.css';

const cx = classnames.bind(styles);

export default function DefaultCallToAction(props) {
  // Avoid a warning by not passing in the isLoggedIn prop, as the Button doesn't know what that is
  // eslint-disable-next-line react/prop-types
  const { isLoggedIn, ...rest } = props;
  return (
    <Button
      as="a"
      className={cx('cta')}
      primary
      size="lg"
      {...rest}
    />
  );
}
