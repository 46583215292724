import { Header, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { canonicalizeString } from '../../../lib/utils';
import { COUNTRY_CODES } from '../../constants';
import CAN_PROVINCES from '../../constants/caProvinces';
import USA_STATES from '../../constants/usStates';
import styles from './SearchStateCloud.module.css';

const cx = classnames.bind(styles);

function getRegionsByCountryCode(countryCode) {
  switch (countryCode) {
    case COUNTRY_CODES.CANADA:
      return CAN_PROVINCES;
    default:
      return USA_STATES;
  }
}

export default function SearchStateCloud({ country }) {
  const isCanada = country === COUNTRY_CODES.CANADA;
  const regions = getRegionsByCountryCode(country);
  const headerText = `Search cheap gas by ${isCanada ? 'province' : 'state'}`;
  const subHeaderText = `Find the best gas prices in your ${isCanada ? 'province' : 'state'} to maximize savings at the pump.`;

  return (
    <Fragment>
      <Header as="h2" className={cx('header')}>
        {headerText}
      </Header>
      <Text as="p" centered>
        {subHeaderText}
      </Text>
      <div className={cx('contents', { outsideUS: country !== COUNTRY_CODES.USA })}>
        {regions.map(state => (
          <div key={`state-${state.abbr}`} className={cx('stateContainer')}>
            <Link
              to={canonicalizeString(`/gasprices/${state.name}`)}
              className={cx('state')}
              aria-label={`${state.name} prices`}
            >
              {state.abbr}
            </Link>
          </div>
        ))}
      </div>
    </Fragment>
  );
}

SearchStateCloud.propTypes = {
  country: PropTypes.oneOf(Object.values(COUNTRY_CODES)),
};

SearchStateCloud.defaultProps = {
  country: COUNTRY_CODES.USA,
};
