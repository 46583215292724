import gql from 'graphql-tag';
import { useMutation, useQuery } from '@apollo/client';
import { Actions, Header, Image, Link, Modal, Text } from '@gasbuddy/react-components';
import { useWindowSize, useLocalStorage } from '@gasbuddy/react-hooks';
import { addDays } from 'date-fns';
import PropTypes from 'prop-types';
import { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import useAnalytics from '../../../lib/hooks/useAnalytics';
import performTracking from '../../../lib/utils/performTracking';

export const FETCH_HOME_SCREEN_CARDS = gql`
  query GetHomeScreenCards {
    toastMessages {
      cards {
        name
        title
        type
        ctaLabel
        ctaUrl
        secondaryCta {
          label
          url
        }
        body
        imageUrl
        imageWidthDp
        imageHeightDp
        web
        suppressed
        track {
          event
          action
          variant
          values {
            key
            value
          }
        }
      }
    }
  }
`;

export const TRACK_EVENT = gql`
  mutation TrackEvent($event: String, $source: String) {
    trackEvent(event: $event, source: $source) {
      success
    }
  }
`;

function getAnalyticsEventWithContext(event = {}) {
  const { values } = event;
  const context = values?.reduce((result, obj) => ({
    ...result,
    [obj.key]: obj.value,
  }), {}) || [];

  return {
    ...event,
    values: context,
  };
}

/**
 * returns an object categorizing tracking events into view events, primary cta events and secondary cta events
 * @param {*} events
 */
function getCategorizedTrackingEvents(events = []) {
  const trackableEvents = events
    .filter(t => !['remove', 'unsuppress'].includes(t.event))
    .map(getAnalyticsEventWithContext);

  const primaryCtaEvents = trackableEvents.filter(({ event }) => event === 'cta_click');
  const secondaryCtaEvents = trackableEvents.filter(({ event }) => event === 'secondary_cta_click');
  const viewEvents = trackableEvents.filter(({ event }) => event === 'view');
  const dismissalEvents = trackableEvents.filter(({ event }) => event === 'dismissed');

  return {
    primaryCtaEvents,
    secondaryCtaEvents,
    viewEvents,
    dismissalEvents,
  };
}

/**
 * Filters tracking events into first party tracking events, localytics events and external tracking events
 * @param {*} events Tracking events found in card.track prop
 * @param {*} source name of the card from which tracking is to be triggered
 */
function filterTrackingEvents(events, source) {
  const firstPartyEvents = events.filter(e => e.action === 'internal_post').map(e => ({ ...e, source }));
  const localyticsEvents = events.filter(e => e.action === 'localytics');
  const externalTrackingEvents = events.filter(e => e.action === 'url');

  return {
    firstPartyEvents,
    localyticsEvents,
    externalTrackingEvents,
  };
}

/**
 * Records analytics events using localytics
 * @param {*} events An array of events with each array member having "event" as event name and "context" as data to record for the event
 */
function recordLocalyticsEvents(context, events) {
  events.forEach((e) => {
    context.tagEvent({ name: e.variant, attributes: e.values });
  });
}

function fireAnalyticsEvents(context, events, source, firstPartyTrackingFn) {
  const {
    firstPartyEvents,
    localyticsEvents,
    externalTrackingEvents,
  } = filterTrackingEvents(events, source);

  firstPartyEvents.forEach((e) => {
    firstPartyTrackingFn({
      variables: {
        event: e.event,
        source: e.source || source,
      },
    });
  });

  recordLocalyticsEvents(context, localyticsEvents);

  performTracking(externalTrackingEvents);
}

function HomeScreenCardModalContent({ card }) {
  const [performFirstPartyTracking] = useMutation(TRACK_EVENT);
  const { mobile: isOnMobileDevice } = useWindowSize();
  const analytics = useAnalytics();

  const handlePrimaryCtaClicked = useCallback(() => {
    const { primaryCtaEvents } = getCategorizedTrackingEvents(card?.track);
    fireAnalyticsEvents(analytics, primaryCtaEvents, card.name, performFirstPartyTracking);
  }, [analytics, card, performFirstPartyTracking]);

  const handleSecondaryCtaClicked = useCallback(() => {
    const { secondaryCtaEvents } = getCategorizedTrackingEvents(card?.track);
    fireAnalyticsEvents(analytics, secondaryCtaEvents, card.name, performFirstPartyTracking);
  }, [analytics, card, performFirstPartyTracking]);


  const {
    title,
    body,
    imageUrl,
    imageWidthDp: imageWidth,
    imageHeightDp: imageHeight,
    secondaryCta,
    ctaUrl: primaryCtaUrl,
    ctaLabel: primaryCtaLabel,
  } = card;

  const imageSizeMultiplier = isOnMobileDevice ? 1.5 : 2;

  const finalImageUrl = imageUrl
    .replace('{{widthpx}}', imageWidth * imageSizeMultiplier)
    .replace('{{heightpx}}', imageHeight * imageSizeMultiplier);

  const replaceLineBreaks = text => text.replace(/(\n)/g, '<br />');

  return (
    <Fragment>
      <Header
        as="h3"
        color="midnight"
        dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
          __html: replaceLineBreaks(title),
        }}
      />
      <br />
      <Text as="p" centered>
        <Image
          src={finalImageUrl}
          alt={title}
        />
      </Text>
      <br />
      <Text
        as="p"
        dangerouslySetInnerHTML={{ // eslint-disable-line react/no-danger
          __html: replaceLineBreaks(body),
        }}
      />
      <br />
      <Actions>
        <Actions.Button
          primary
          as="a"
          href={primaryCtaUrl}
          onClick={handlePrimaryCtaClicked}
          fluid
          tablet={secondaryCta ? 6 : 4}
          desktop={secondaryCta ? 6 : 4}
        >
          {primaryCtaLabel}
        </Actions.Button>
        {!!secondaryCta && (
          <Actions.Action tablet={6} desktop={6}>
            <Link
              to={secondaryCta.url}
              onClick={handleSecondaryCtaClicked}
              uppercase
            >
              {secondaryCta.label}
            </Link>
          </Actions.Action>
        )}
      </Actions>
    </Fragment>
  );
}

HomeScreenCardModalContent.propTypes = {
  card: PropTypes.shape({
    name: PropTypes.string.isRequired,
    type: PropTypes.oneOf(['card', 'toast', 'root']).isRequired,
    title: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
    imageWidthDp: PropTypes.number.isRequired,
    imageHeightDp: PropTypes.number.isRequired,
    ctaLabel: PropTypes.string.isRequired,
    ctaUrl: PropTypes.string.isRequired,
    secondaryCta: PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
    track: PropTypes.arrayOf(
      PropTypes.shape({
        event: PropTypes.oneOf([
          'suppress', // ignore for tracking
          'remove', // ignore tracking
          'unsuppress', // ignore tracking
          'view', // fire when modal is shown
          'cta_click', // fire when primary action executes
          'secondary_cta_click', // fire when secondary action executes
          'dismissed', // fire when modal is dismissed
        ]).isRequired,
        action: PropTypes.oneOf([
          'internal_post', // fire a first party event to UCS
          'localytics', // fire a localytics event
          'url', // fire a GET request using performTracking util
          'webapi', // ignore tracking
        ]).isRequired,
        variant: PropTypes.string, // only gets provided if action === localytics
        values: PropTypes.arrayOf( // send this for localytics or first party tracking
          PropTypes.shape({
            key: PropTypes.string,
            value: PropTypes.string,
          }),
        ),
      }),
    ),
  }).isRequired,
};

export default function HomeScreenCardModal() {
  const { data: homeScreenCards } = useQuery(FETCH_HOME_SCREEN_CARDS);
  const [performFirstPartyTracking] = useMutation(TRACK_EVENT);
  const [nextHomeScreenCardInterval, setNextHomeScreenCardInterval] = useLocalStorage('nextHomeScreenCardInterval', '');
  const [shownHomeScreenCardsJson, setShownHomeScreenCardsJson] = useLocalStorage('shownHomeScreenCards', '[]');
  const [activeCard, setActiveCard] = useState();
  const analytics = useAnalytics();

  const shownHomeScreenCards = JSON.parse(shownHomeScreenCardsJson);
  const { cards } = homeScreenCards?.toastMessages || {};

  // We should only show next home screen card modal if the set interval has passed already
  const shouldShowNextModal = useMemo(
    () => (nextHomeScreenCardInterval ? Date.now() > new Date(nextHomeScreenCardInterval).valueOf() : true),
    [nextHomeScreenCardInterval],
  );

  const handleModalShown = useCallback(() => {
    const { viewEvents } = getCategorizedTrackingEvents(activeCard?.track);
    fireAnalyticsEvents(analytics, viewEvents, activeCard.name, performFirstPartyTracking);
    // Update next home screen card to appear after 2 days
    setNextHomeScreenCardInterval(addDays(Date.now(), 2));
    // Update cards that have been shown to user
    if (!shownHomeScreenCards.includes(activeCard.name)) {
      setShownHomeScreenCardsJson(JSON.stringify([].concat(shownHomeScreenCards, activeCard.name)));
    }
  }, [activeCard, analytics, performFirstPartyTracking, setNextHomeScreenCardInterval, shownHomeScreenCards, setShownHomeScreenCardsJson]);

  const handleModalDismissed = useCallback(() => {
    const { dismissalEvents } = getCategorizedTrackingEvents(activeCard?.track);
    fireAnalyticsEvents(analytics, dismissalEvents, activeCard.name, performFirstPartyTracking);
    setActiveCard(undefined);
  }, [activeCard, analytics, performFirstPartyTracking]);

  useEffect(() => {
    if (!activeCard && cards?.length && shouldShowNextModal) {
      // Show a card with suppressed: false aka unsuppressed/active and has not been shown yet
      const nextHomeScreenCard = cards.find(c => !c.suppressed && !shownHomeScreenCards.includes(c.name));
      if (nextHomeScreenCard) {
        setActiveCard(nextHomeScreenCard);
      }
    }
  }, [shouldShowNextModal, cards, activeCard, shownHomeScreenCards, setShownHomeScreenCardsJson]);

  return !!activeCard && (
    <Modal
      content={(
        <HomeScreenCardModalContent card={activeCard} />
      )}
      size="sm"
      onOpen={handleModalShown}
      onClose={handleModalDismissed}
      forceIsShowing
      bgClickable={false}
    />
  );
}
