import { Button, Container, FlexGrid, Header, Image, Link, Text } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Fragment, useCallback } from 'react';
import useAnalytics from '../../../lib/hooks/useAnalytics';
import { ANALYTICS_EVENTS } from '../../constants';
import BrandBubble from '../LogoBubble';
import styles from './HomePageGasBackBrands.module.css';

const cx = classnames.bind(styles);

export default function HomePageGasBackBrands({
  brands,
  title: infoTitle,
  text: infoText,
  inverse,
  ...rest
}) {
  const analytics = useAnalytics();
  const visibleBrands = brands.slice(0, Math.min(brands.length, 7));

  const leftBrands = visibleBrands.slice(0, Math.floor(visibleBrands.length / 2.0));
  const rightBrands = visibleBrands.slice(leftBrands.length);

  const handleLinkClick = useCallback(() => {
    analytics.tagEvent({ name: ANALYTICS_EVENTS.GASBACK_LEARN_MORE_CLICKED });
  }, [analytics]);

  return (
    <Container {...rest}>
      <FlexGrid className={cx({ inverse })}>
        <FlexGrid.Column mobile={12} desktop={6}>
          <FlexGrid className={cx('bubblesPhoneContainer')}>
            <FlexGrid.Column className={cx('leftBubbles')} mobile={3} tablet={3} desktop={2}>
              {leftBrands.map(({ title, logo }) => (
                <BrandBubble className={cx('bubble')} key={logo} logoUrl={logo} title={title} />
              ))}
            </FlexGrid.Column>
            <FlexGrid.Column className={cx('rightBubbles')} mobile={3} tablet={3} desktop={2}>
              {rightBrands.map(({ title, logo }) => (
                <BrandBubble className={cx('bubble')} key={logo} logoUrl={logo} title={title} />
              ))}
            </FlexGrid.Column>
            <FlexGrid.Column className={cx('phoneSection')} mobile={6} tablet={6} desktop={8}>
              <Image
                lazy
                alt="iPhone X With GasBuddy App"
                className={cx('phone')}
                srcSet={`
                  https://static.gasbuddy.com/web/consumer/phones/iphone_X_gasback_top@2x.png 2x,
                  https://static.gasbuddy.com/web/consumer/phones/iphone_X_gasback_top@3x.png 3x
                `}
                src="https://static.gasbuddy.com/web/consumer/phones/iphone_X_gasback_top.png"
              />
            </FlexGrid.Column>
          </FlexGrid>
        </FlexGrid.Column>
        <FlexGrid.Column className={cx('infoColumn')} mobile={12} desktop={6}>
          <Header as="h2" className={cx('headerText')} color="grey">
            {infoTitle}
          </Header>
          <Text as="p" className={cx('contentText')}>
            {infoText}
          </Text>
          <br />
          <br />
          <Button
            as="a"
            href="/gasback"
            className={cx('button')}
            onClick={handleLinkClick}
            primary
            size="lg"
            wide
          >
            <span>Learn More</span>
            <Text screenReader> About GasBack</Text>
          </Button>
        </FlexGrid.Column>
      </FlexGrid>
    </Container>
  );
}

HomePageGasBackBrands.propTypes = {
  brands: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string,
    logo: PropTypes.string,
  })),
  title: PropTypes.string,
  text: PropTypes.node,
  inverse: PropTypes.bool,
};

HomePageGasBackBrands.defaultProps = {
  brands: [],
  title: 'Earn GasBack (FREE gas!) to redeem at the pump.',
  text: (
    <Fragment>
      Turn everyday purchases into free money for gas. Take advantage of shopping and dining deals through GasBuddy and earn GasBack to spend at the pump using your <Link to="/pay">Pay with GasBuddy</Link> card.
    </Fragment>
  ),
  inverse: false,
};
