export const US = [
  {
    id: 1,
    text: 'GasBuddy was always my go-to for pricing; now it\'s my go-to for pricing and discounts... the GasBuddy card is right at the top of my pile of cards. It\'s the first thing I see, the first thing I want to use.',
    name: 'Skip',
    username: 'Skipross55',
  },
  {
    id: 3,
    text: 'I am a courier and spend an average of $207 a week on fuel. I love saving up to 25 cents on every gallon using my GasBuddy card, especially with E85 being 40 cents cheaper than regular. When I go out of town on a long distance delivery, it is great to easily find the cheapest gas with directions to find it.',
    name: 'Joe',
    username: 'JoeHengst',
  },
  {
    id: 8,
    text: 'GasBuddy is a great tool. I check the rates all the time and besides saving me up to 25 cents per gallon it informs me where to go to get the best prices.',
    name: 'Louis',
    username: 'louques1',
  },
  {
    id: 7,
    text: 'I\'ve loved GasBuddy ever since I signed up. It saves me money and since I drive a LOT, I really appreciate it. Great that it works on top of my other discount cards also. I frequently save $.15 per gallon when I fill up at select locations. Keep up the good work.',
    name: 'Frank',
    username: 'frankkertis',
  },
  {
    id: 9,
    text: 'GasBuddy saves me $$ with every fuel purchase, and since I buy daily my savings really add up!',
    name: 'Jack',
    username: 'mag44laf',
  },
  {
    id: 6,
    text: 'I drive quite a bit, filling up my tank 3 or 4 times a week. GasBuddy is always there to let me know where the best place to fill up is, even if I am in an unfamiliar area of town or traveling. Using the Pay with GasBuddy feature helps me to save even more on my frequent fill ups.',
    name: 'Andy',
    username: 'andyrefman',
  },
  {
    id: 5,
    text: 'Saving with GasBuddy was really easy. It is really nice knowing that I am saving money whenever I use the app. I drive for a living and every penny counts. I have been able to save hundreds of dollars and has allowed me to use that money in other areas.',
    name: 'Robert',
    username: 'robtiff2011',
  },
  {
    id: 2,
    text: 'I have been using the GasBuddy app for a long time and it alone had saved me a lot of money. When I saw the fuel card I decided I’d give it a try and I do not regret it. The card saves you money, and is accepted at a ton of stations. I have saved over $200.00 since I signed up. If you’re on the fence, trust me just go for it!',
    name: 'Quinn',
    username: 'broncodriver16',
  },
];

export const CA = [
  {
    text: 'Fantastic app! If I could give a much higher score, I would. Love G.B.! Saves me bundles the way I drive! I can check prices at all my stop points of the day simply by accessing this tool. This way, I can find the cheapest, closest spots and decide where to go efficiently, saving time, money AND miles.',
    name: 'hannah',
    stars: 5,
  },
  {
    text: 'This is by far my favorite app to find gas prices. It consistently verifies the prices so 99.9% of the time the price at the pump is the price on the app. That’s a huge deal for me living on a budget and with gas prices fluctuating day by day it gives you that alert to tell you when there is a spike in gas going up.',
    name: 'Baxterodiehoosier',
    stars: 5,
  },
  {
    text: 'GasBuddy is an absolute MUST HAVE! It’s so simple! ...They have challenges like reporting a certain number of gas prices at various stations. To reward you they give you points that you can then use to enter a drawing for a FREE $100 gas gift card! You would have to be completely bonkers to not using GasBuddy.',
    name: 'KJ_Miller',
    stars: 5,
  },
  {
    text: 'GasBuddy provides a great service to people who are on the road a lot...GasBuddy allows me to fill up at the optimum location and time of day without wasting time driving around looking for the best price. I will often save $20 to $30 per fill up without changing my schedule but by paying attention to what the gas prices will be along the way.',
    name: 'gassluver',
    stars: 5,
  },
  {
    text: 'For understanding prices in your area, reporting prices and comparing, a great app. If you are on a budget, or simply trying to find a best buy on gasoline in your area, you won’t find a better app. As with all of these self report apps, they depend on the users to contribute so that all may get value. Great savings $$$ app.',
    name: 'Taimen',
    stars: 5,
  },
  {
    text: 'I always go to this when trying to save money, especially since I drive a 1 ton diesel. It has saved me plenty of money since I started using this app. If you have the chance give this app a try and if you don’t then it’s your loss on fuel savings.',
    name: 'crownqltesq',
    stars: 5,
  },
  {
    text: 'GasBuddy is a huge help in my business. I travel the Northern section of my state and depend on GasBuddy to help me save my hard earned money! It is a great tool! Thank you for making it FREE.',
    name: 'mississippiamy',
    stars: 5,
  },
  {
    text: 'I drive approximately 40 mile one way to work. Needless to say I need to stop a lot for gas. GasBuddy lets me know the cheapest gas on my normal commuting route. Great app, highly recommend.',
    name: 'high mikes',
    stars: 5,
  },
  {
    text: 'It’s an amazing app, it’s hard not to love it especially when you’re traveling and don’t know the area. Easy way to find gas, especially on a motorcycle when you have a small tank to work with.',
    name: 'heroqltetmo',
    stars: 5,
  },
];
