import PropTypes from 'prop-types';
import { Image } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import styles from './LogoBubble.module.css';

const cx = classnames.bind(styles);

export default function LogoBubble({ className, logoUrl, title, ...rest }) {
  return (
    <div className={cx('logoCircle', className)} {...rest}>
      <Image alt=" " src={logoUrl} title={title} lazy />
    </div>
  );
}

LogoBubble.propTypes = {
  className: PropTypes.string,
  logoUrl: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

LogoBubble.defaultProps = {
  className: undefined,
};
