import { Button } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import { useCallback } from 'react';
import useAnalytics from '../../../lib/hooks/useAnalytics';
import { ANALYTICS_EVENTS } from '../../constants';
import styles from './GetTheAppButton.module.css';

const cx = classnames.bind(styles);

export default function GetTheAppButton({ className, screen, section, ...rest }) {
  const analytics = useAnalytics();
  const handleButtonClick = useCallback(() => {
    if (screen && section) {
      analytics.tagEvent({
        name: ANALYTICS_EVENTS.APP_STORE_LINK_CLICK,
        attributes: {
          Store: 'Unknown',
          'Screen Name': screen,
          Section: section,
        },
      });
    }
  }, [analytics, screen, section]);

  return (
    <Button
      as="a"
      className={cx('cta', className)}
      cta
      href="//routing.gasbuddy.com/app"
      fluid
      onClick={handleButtonClick}
      primary
      size="lg"
      {...rest}
    >
      Download The App
    </Button>
  );
}

GetTheAppButton.propTypes = {
  className: PropTypes.string,
  screen: PropTypes.string,
  section: PropTypes.string,
};

GetTheAppButton.defaultProps = {
  className: undefined,
  screen: undefined,
  section: undefined,
};
