import { Header, Panel } from '@gasbuddy/react-components';
import classnames from 'classnames/bind';
import PropTypes from 'prop-types';
import Metric from './Metric';
import styles from './MetricsBox.module.css';


const cx = classnames.bind(styles);

export default function MetricsBox({ metrics, header, className, children, ...rest }) {
  return (
    <div className={cx('centered')}>
      <Panel
        className={cx('container', className)}
        color="white"
        inline
        {...rest}
      >
        {!!header && (
          <Header as="h2" className={cx('header')} color="grey">
            {header}
          </Header>
        )}
        {metrics.length
          ? (
            <div className={cx('metrics')}>
              {metrics.map(metric => (
                <Metric key={metric.key} {...metric} />
              ))}
            </div>
          )
          : children
        }
      </Panel>
    </div>
  );
}

MetricsBox.propTypes = {
  header: PropTypes.string,
  metrics: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    header: PropTypes.string,
    text: PropTypes.string,
    iconUrl: PropTypes.string,
  })),
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node]),
};

MetricsBox.defaultProps = {
  header: undefined,
  metrics: [],
  className: undefined,
  children: undefined,
};
